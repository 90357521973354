@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.slider {
  padding: 30px 0 0 0;
  margin: 0 -10px;

  @media screen and (min-width: vars.$large) {
    padding: 60px 0 0 0;
  }
}

.slider .title {
  font-weight: bold;
  margin-left: 15px;
  margin-bottom: 15px;
  @media screen and (min-width: vars.$medium) {
    margin-bottom: 30px;
  }
  @media screen and (min-width: vars.$large) {
    margin-left: 0;
  }
}

.slider .title span {
  color: colors.$primary-color;
  font-weight: 300;
}

.sliderContainer {
  position: relative;
}

.slides {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 10px;
  transition: transform 0.5s ease-in-out;
  scroll-behavior: smooth;
  padding-bottom: 10px;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.slides,
.slidesHiddenScroll,
.slidesHiddenScroll:hover {
  @media screen and (min-width: vars.$extra-large) {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.slides .card {
  scroll-snap-align: start;
}

.slides:after {
  content: '';
  min-width: 15px;
  height: auto;
  @media screen and (min-width: vars.$large) {
    display: none;
  }
}

.navigationPrev,
.navigationNext {
  cursor: pointer;
  display: none;
  height: 80px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 15px));
  width: 40px;
  z-index: 1;

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navigationNext {
  right: 0px;
  border-bottom-left-radius: 80px;
  border-top-left-radius: 80px;
  box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.1);
}

.navigationPrev {
  left: 0px;
  border-bottom-right-radius: 80px;
  border-top-right-radius: 80px;
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1);
}

.seeAll svg {
  border: 1px solid colors.$primary-color;
  border-radius: 50%;
  box-sizing: content-box;
  padding: 15px;
}

.navigationNext:hover svg,
.navigationPrev:hover svg {
  opacity: 1;
}

.sliderContainer:hover .navigationPrev,
.sliderContainer:hover .navigationNext {
  opacity: 1;
}

.sliderContainer:hover .slides {
  @media screen and (min-width: vars.$extra-large) {
    padding-bottom: 10px;
    -ms-overflow-style: block; /* IE and Edge */
    scrollbar-width: block; /* Firefox */
    &::-webkit-scrollbar {
      display: block;
    }
  }
}

.seeAll {
  align-items: center;
  color: colors.$primary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.seeAll a {
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
}

.seeAll a:hover {
  text-decoration: underline;
}

.pagination {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.thumb {
  height: 6px;
  margin-right: 5px;
  width: 6px;
  &:before {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 6px;
    width: 6px;
  }
}

.thumbActive:before {
  background-color: white;
}

.seeAllCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  width: 200px;
  height: calc(100% - vars.$gutter);
  border: 2px solid var(--light-blue);
  border-radius: 4px;
  text-decoration: none;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  background-color: white;
  cursor: pointer;
  height: 100%;

  @media screen and (min-width: vars.$medium) {
    width: 227px;
  }
}

.navigationNext.roundNavigation,
.navigationPrev.roundNavigation {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid var(--light-blue);
  box-shadow: none;

  svg {
    fill: var(--light-blue);
  }
}

.navigationNext.roundNavigation {
  right: -20px;
  background-color: colors.$white;
}

.navigationPrev.roundNavigation {
  left: -20px;
  background-color: colors.$white;
}
